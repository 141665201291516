import React, { useEffect } from 'react';

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className="container-fluid">
            <div className="row text-center about-row py-3">
                <div className="col-9 mx-auto">
                    <section className="mb-1">
                        <h1>מי אני?</h1>
                        <p>כבר 15 שנים שאני מסתובב בעולם עם מצלמה ותופס רגעים מעניינים. אירועים מיוחדים, טיולים, חוויות עם חברים, ועוד. לאחרונה התחיל להימאס עליי להיות תלוי בפייסבוק ובאינסטגרם כדי להציג את התמונות שלי, ולמעשה הן אפילו לא משרתות את המטרה.
                            בפייסבוק התמונות נבלעות בים הסטטוסים ובאינסטגרם לא מציגים תמונות באיכות גבוהה. כמו תמיד, שוב החלטתי לעשות הכול בעצמי ולבנות את האתר הזה, גם כדי לתרגל כתיבת קוד וריאקט במסגרת לימודי הפרונט אנד. זה בטח ייראה טוב בתיק העבודות שלי.</p>
                        <img src="assets/images/profile.jpg" alt="מילי שמידט" />
                    </section>
                    <section className="">
                        <h1>מה רואים פה?</h1>
                        <p>אספתי שישה עשר אלבומים מכל האלבומים שלי, וצמצמתי אותם בקושי רב לתמונות שמצד אחד אני אוהב ומצד שני, שחשבתי שלאחרים יהיה בהן עניין. לא כל התמונות הן תמונות "טובות", במובן המקצועי, אבל יש בהן זיכרונות ששווה לפרסם.
                            לא תכננתי שזה יהיה אתר טיולים, אבל אני חושב שרוב האלבומים מציגים את הטיולים שלי בארץ ובחו"ל, לפעמים לבד ולפעמים עם חברים. כנראה שאני מטייל הרבה, אז זה מה שיצא. אתם מוזמנים להסתכל וליהנות. בעתיד תתווסף גם האפשרות להגיב לכל תמונה ולכל אלבום.</p>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default About;
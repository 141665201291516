export const TELAVIV = [
    {
        id: 0,
        path: 'tel-aviv-walk',
        src: 'assets/images/telavivjourney/picture0.png',
        alt: 'דגל בחלון',
        caption: 'אל תדרוך עליי, בדירה של אנה בתל אביב'
    },
    {
        id: 1,
        path: 'tel-aviv-walk',
        src: 'assets/images/telavivjourney/picture1.png',
        alt: 'אנה',
        caption: 'הנה אנה!'
    },
    {
        id: 2,
        path: 'tel-aviv-walk',
        src: 'assets/images/telavivjourney/picture2.png',
        alt: 'צוקי החתול',
        caption: 'צוקי החתול, פעם הוריד לי את אחת מאצבעות הרגליים'
    },
    {
        id: 3,
        path: 'tel-aviv-walk',
        src: 'assets/images/telavivjourney/picture3.png',
        alt: 'אנה מדברת',
        caption: 'לא זוכר על מה דיברנו'
    },
    {
        id: 4,
        path: 'tel-aviv-walk',
        src: 'assets/images/telavivjourney/picture4.png',
        alt: 'עץ בפארק',
        caption: 'הפארק ברחוב קפלן בתל אביב'
    },
    {
        id: 5,
        path: 'tel-aviv-walk',
        src: 'assets/images/telavivjourney/picture5.png',
        alt: 'מכוניות על רחוב קפלן',
        caption: 'רחוב קפלן מחבר את עזריאלי לדיזנגוף סנטר, זה המסלול הקבוע שלי'
    },
    {
        id: 6,
        path: 'tel-aviv-walk',
        src: 'assets/images/telavivjourney/picture6.png',
        alt: 'מכוניות נוסעות בכביש',
        caption: 'מכוניות נוסעות לכל הכיוונים'
    },
    {
        id: 7,
        path: 'tel-aviv-walk',
        src: 'assets/images/telavivjourney/picture7.png',
        alt: 'צומת אבן גבירול',
        caption: 'לפעמים יש רגעים של שקט בכבישי תל אביב, כמו בצומת אבן גבירול הזאת'
    },
    {
        id: 8,
        path: 'tel-aviv-walk',
        src: 'assets/images/telavivjourney/picture8.png',
        alt: 'נוף בדרום תל אביב',
        caption: 'דרום תל אביב מהחלון'
    },
    {
        id: 9,
        path: 'tel-aviv-walk',
        src: 'assets/images/telavivjourney/picture9.png',
        alt: 'חניון בדרום תל אביב',
        caption: 'נוף מעניין מאוד יש בחלון הזה'
    },
    {
         id: 10,
         path: 'tel-aviv-walk',
         src: 'assets/images/telavivjourney/picture92.png',
         alt: 'יעל',
         caption: 'תודה, יעלי!'
    },
    {
         id: 11,
         path: 'tel-aviv-walk',
         src: 'assets/images/telavivjourney/picture93.png',
         alt: 'פירוק בשר באטליז',
         caption: 'יום למחרת הלכתי לראות את ליה מפרקת בשר באטליז'
    },
    {
        id: 12,
        path: 'tel-aviv-walk',
        src: 'assets/images/telavivjourney/picture94.png',
        alt: 'יואב איתמר',
        caption: 'באותו שוק היה גם יואב'
    },
    {
         id: 13,
         path: 'tel-aviv-walk',
         src: 'assets/images/telavivjourney/picture95.png',
         alt: 'יעל גורן',
         caption: 'גם זו יעל, אבל אותה נדיר יותר לפגוש, ואי אפשר לדעת באיזו עיר'
    }
]